<template>
  <div>
    Redirecting...
  </div>
</template>

<script>
export default {
  created() {
    const params = this.$route.params;
    if (params.inviteCode) {
      var currentDate = new Date();

      // Calculate a date far in the future (e.g., 10 years from now)
      var expirationDate = new Date(currentDate.getTime() + 10 * 365 * 24 * 60 * 60 * 1000); // 10 years in milliseconds
      var expirationDateString = expirationDate.toUTCString();

      // Set the cookie
      document.cookie = `referralInviteCode=${params.inviteCode}; expires=` + expirationDateString + "; domain=joinhomeplan.com; path=/";
    
      location.href = "https://beta.joinhomeplan.com/signup?inviteCode=" + params.inviteCode
    }
  }
}
</script>